<template>
  <div v-loading="loading">
    <web-header active-url="/about" borderBottom></web-header>
    <div class="single-page">
      <div class="f-wrap">
        <div class="f-wrap-container">
          <div class="title">{{ info.title }}</div>
          <div class="content" v-html="info.content"></div>
        </div>
      </div>
    </div>
    <web-navigation></web-navigation>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import WebNavigation from "@/components/webNavigation";
import { singleDetail } from "@/api";
export default {
  name: 'Detail',
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    WebNavigation
  },
  data() {
    return {
      loading: false,
      info: {}
    }
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      this.getDataDetail(this.$route.params.id);
    }
  },
  methods: {
    // 获取获取详情
    async getDataDetail(id) {
      this.loading = true;
      let res = await singleDetail(id);
      if (res && res.code == 200) {
        this.info = res.data;
        if (this.info.jump_link) {
          this.routerLink(this.info.jump_link, false);
        }
      }
      this.loading = false;
    }
  }
}
</script>
<style lang="scss">
.single-page {
  padding-top: 100px;
  padding-bottom: 120px;
  background-color: #f7f7f7;
  .f-wrap {
    background-color: #ffffff;
    padding: 60px 0 70px;
    &-container {
      width: 790px !important;
      margin: 0 auto;
      .title {
        margin-bottom: 40px;
        text-align: center;
        font-size: 24px;
        font-family: "微软雅黑" !important;
      }
      .content {
        display: inline-block;
        span {
          margin-bottom: 18px !important;
          display: inline-block;
          line-height: 32px;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .single-page {
    padding-top: 60px;
    padding-bottom: 80px;
    .f-wrap {
      &-container {
        width: calc(100% - 50px) !important;
        padding: 0 25px;
        height: auto;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .single-page {
    padding-top: 50px;
    padding-bottom: 60px;
    .f-wrap {
      &-container {
        width: calc(100% - 50px) !important;
        padding: 0 25px;
        height: auto;
      }
    }
  }
}
</style>